import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logoloader.propTypes = {
  className: PropTypes.string
};

function Logoloader({ className, ...other }) {
  return (
    <Box
      component="img"
      alt="logo"
      src="/static/brand/logoloader.svg"
      height={40}
      className={className}
      {...other}
    />
  );
}

export default Logoloader;
